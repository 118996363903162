<template>
  <div class="app-banner-card card flat row p-2 xs-mb-1 mb-2">
    <!-- Loading State -->
    <template v-if="isLoading">
      <div
        class="skeleton banner-thumbnail h-100 xs-col-12 sm-col-4 md-col-3 lg-col-2"
      ></div>
      <div class="xs-col-12 sm-col-8 md-col-9 lg-col-10 sm-pl-2">
        <div class="xs-mt-2 mb-1">
          <span class="skeleton d-inline-block banner-title w-50"> </span>
          <span class="skeleton d-inline-block banner-date w-10 md-ml-2"></span>
        </div>
        <span class="skeleton d-block w-20 mb-2"></span>
        <span class="skeleton btn px-3"> </span>
        <span class="skeleton btn px-3 ml-1"></span>
      </div>
    </template>
    <!-- Non-Loading State -->
    <template v-else>
      <div class="col-12 d-flex align-items-center">
        <fd-button v-if="sortable" class="handle flat circle mr-2">
          <i class="fas fa-grip-lines"></i>
        </fd-button>
        <div class="row w-100">
          <img
            class="banner-thumbnail cursor-pointer xs-col-12 sm-col-4 md-col-3 lg-col-2"
            :src="$getFileURL(banner.image[0])"
            :alt="banner.title"
            @click="$emit('view-image', $getFileURL(banner.image[0]))"
          />
          <div class="xs-col-12 sm-col-8 md-col-9 lg-col-10 sm-pl-2">
            <div class="xs-mt-2 mb-1">
              <span class="banner-title sm-col-12"> {{ banner.title }} </span>
              <span class="banner-date ml-1">{{
                $moment(banner.createdAt).format("DD-MM-YYYY")
              }}</span>
            </div>
            <chip class="d-block mb-2">{{ banner.type }}</chip>
            <!-- Actions -->
            <fd-button
              class="bordered main px-3"
              @click="$emit('edit', banner)"
            >
              Edit
            </fd-button>
            <fd-button
              class="bordered danger px-3 ml-1"
              @click="$emit('delete', banner.id)"
              >Delete</fd-button
            >
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  components: {
    Chip: () => import("@/components/GlobalComponents/Chip")
  },
  mixins: [],
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    banner: {
      type: Object,
      default: () => ({})
    },
    sortable: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
.app-banner-card {
  .banner-thumbnail {
    $width: 250px;
    width: $width;
    aspect-ratio: 16/9;
    height: $width * 9 / 16;
    object-fit: cover;
    border-radius: 5px;
  }
  .banner-title {
    font-size: 18px;
  }
  .banner-date {
    color: #777;
  }
}
</style>
